import { render, staticRenderFns } from "./MainHeroSliderSection.vue?vue&type=template&id=3c05be00"
import script from "./MainHeroSliderSection.vue?vue&type=script&lang=js"
export * from "./MainHeroSliderSection.vue?vue&type=script&lang=js"
import style0 from "./MainHeroSliderSection.vue?vue&type=style&index=0&id=3c05be00&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIImg: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIImg.vue').default,UIButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIButton.vue').default,AppContainer: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppContainer.vue').default,SliderFraction: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/SliderFraction.vue').default,AppSection: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppSection.vue').default})
