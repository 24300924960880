
import { defineComponent } from '@nuxtjs/composition-api';
import { isCdnAsset } from '~/helpers/isCdnAsset';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    format: {
      type: String,
      default: 'webp',
    },
    placeholder: {
      type: [String, Boolean],
      default: '',
    },
    sizes: {
      type: String,
      default: '',
    },
    preset: {
      type: String,
      default: '',
    },
    quality: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: '',
    },
    preload: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: 'image',
    },
  },
  setup(props) {
    const getProvider = isCdnAsset(props.src) ? 'imgproxy' : 'ipx';

    return {
      getProvider,
      isCdnAsset,
    };
  },
});
